@import "variables";

.Loader {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin: 2rem auto;
    border: 5px solid transparent;
    border-bottom-color: $primary;
    border-top-color: $primary;
    animation: rotate .8s linear 0s infinite;

    &-backcover::before {
        opacity: .8;
        z-index: 999998;
        display: block;
        position: fixed;
        background-color: $white;
        content: '';
        width: 100%;
        height: 100%;
    }
}

